import { createMarkup } from "../../utils";

function DetailsTable({ values }) {
	return (
		<table style={{ marginTop: "25px" }}>
			<thead>
				<tr>
					<th colSpan="2" className="hdg">
						Participant Details
					</th>
				</tr>
			</thead>
			{values[0].value ? (
				<tbody>
					{values.map((item, index) => {
						return (
							<tr key={index}>
								<th className="right top" style={{ width: "25%" }}>
									{item.key}
								</th>
								<td
									className="left"
									dangerouslySetInnerHTML={createMarkup(item.value)}
								/>
							</tr>
						);
					})}
				</tbody>
			) : (
				<tbody>
					<tr>
						<td
							className="center"
							colSpan="2"
							style={{ paddingTop: "15px", paddingBottom: "15px" }}
						>
							<span className="working">-- Details not yet submitted --</span>
						</td>
					</tr>
				</tbody>
			)}
		</table>
	);
}

export default DetailsTable;
