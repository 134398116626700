import { Link } from "react-router-dom";

import Button from "../../shared/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheckCircle,
	faHourglass,
	faClock,
	faSquareMinus,
	faSquarePlus,
} from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

function EventTable({ id, details, open = false, toggle }) {
	return (
		<table className="event-table">
			<thead>
				<tr>
					<th colSpan="3" className="hdg">
						{details.title}
					</th>
				</tr>
				<tr>
					<td
						colSpan="3"
						className="left"
						style={{ paddingTop: "15px", paddingBottom: "15px" }}
					>
						<ul>
							{details.bullets.map((bullet, index) => {
								return <li key={index}>{bullet}</li>;
							})}
						</ul>
					</td>
				</tr>
			</thead>
			<thead className={open ? "" : "hidden"}>
				<tr>
					<td className="hdg" style={{ width: "15%" }}>
						Spot
					</td>
					<td className="hdg left">Guest Name</td>
					<td className="hdg" style={{ width: "15%" }}>
						Status
					</td>
				</tr>
			</thead>
			<tbody className={open ? "" : "hidden"}>
				{details.data.map((item, index) => {
					const foursome = 4;

					return item.name ? (
						<tr
							key={index}
							className={item.spot % foursome === 0 ? "bottom" : ""}
						>
							<td className={item.status === 5 ? "working" : ""}>
								{item.spot}
							</td>
							<td className="left">
								{item.status === 1 ? (
									<span>
										<Link to={`/dashboard/guest/${item.id}`}>{item.name}</Link>
									</span>
								) : item.status === 2 ? (
									<span className="working">
										Waiting on response from{" "}
										<Link
											to={`/dashboard/guest/${item.id}`}
											className="working"
											style={{ color: "var(--working)" }}
										>
											{item.name}
										</Link>
									</span>
								) : item.status === 4 ? (
									<span className="notice">
										Invite queued for{" "}
										<Link to={`/dashboard/guest/${item.id}`} className="notice">
											{item.name}
										</Link>
									</span>
								) : item.status === 5 ? (
									<span className="working">{item.name}</span>
								) : (
									""
								)}
							</td>
							<td
								className={
									item.status === 1
										? "center one"
										: item.status === 2
										? "center two"
										: item.status === 4
										? "center"
										: "center"
								}
							>
								{item.status === 1 ? (
									<FontAwesomeIcon icon={faCheckCircle} size="lg" />
								) : item.status === 2 ? (
									<FontAwesomeIcon icon={faHourglass} size="lg" />
								) : item.status === 4 ? (
									<FontAwesomeIcon icon={faClock} size="lg" />
								) : (
									""
								)}
							</td>
						</tr>
					) : (
						<tr
							key={index}
							className={item.spot % foursome === 0 ? "bottom" : ""}
						>
							<td>{item.spot}</td>
							<td>
								<span className="working"> -- empty --</span>
							</td>
							<td className="center"></td>
						</tr>
					);
				})}
			</tbody>
			<tbody>
				<tr>
					<td
						colSpan="3"
						className="center"
						style={{ paddingTop: "15px", paddingBottom: "15px" }}
					>
						<button type="button" onClick={() => toggle(id)}>
							<div>
								{open ? (
									<FontAwesomeIcon icon={faSquareMinus} size="lg" />
								) : (
									<FontAwesomeIcon icon={faSquarePlus} size="lg" />
								)}
								<span className="left-icon">
									{open ? `Hide Details` : `Show Details`}
								</span>
							</div>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

export default EventTable;
