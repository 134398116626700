import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faDownload } from "@fortawesome/pro-light-svg-icons";

function DownloadReport({ report, handleReset }) {
	return (
		<div className="download-container">
			<button type="button" onClick={handleReset}>
				<FontAwesomeIcon icon={faCircleXmark} size="lg" />
			</button>
			<div>
				<FontAwesomeIcon icon={faDownload} size="3x" />
				<div>
					<p>
						<a href={report.url}>{report.filename}</a>
					</p>
					<p>{`${report.records} records / ${report.filesize}`}</p>
				</div>
			</div>
			<p>
				<small>{report.note}</small>
			</p>
		</div>
	);
}

export default DownloadReport;
