function NameTable({ name, email, phone }) {
	return (
		<table style={{ marginTop: "25px" }}>
			<thead>
				<tr>
					<th>Name</th>
					<th>Email</th>
					<th>Phone</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td className="center">{name}</td>
					<td className="center">
						<a href={`mailto:${email}`}>{email}</a>
					</td>
					<td className="center">{phone}</td>
				</tr>
			</tbody>
		</table>
	);
}

export default NameTable;
