import { useState, useEffect, useRef } from "react";

import { API_URL } from "./utils";

import Alert from "./shared/Alert";
import Player from "./shared/Player";

function PlayerField() {
	const [players, setPlayers] = useState([]);

	const initRef = useRef(false);
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	}, []);

	const init = async () => {
		//console.log("init called");

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `cdn`,
			f2: `${encodeURIComponent("/k-api/?f=playerfieldInit")}`,
			q: `${encodeURIComponent(`year=2024`)}`,
			x: `957`,
		};
		url.search = new URLSearchParams(params);

		/*
    const url = new URL(`${API_URL}/`);
    const params = {
      f: "playerfieldInit",
      year: "2023",
      x: "1148",
    };
    */

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setPlayers(json.items);
				setAlert({});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="player-field-container">
					{players.map((player) => {
						return <Player data={player} key={player.id} />;
					})}
				</div>
			)}
		</>
	);
}

export default PlayerField;
