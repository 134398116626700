import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS, scroll } from "../../utils";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";

import Nav from "../Nav";

import Form from "./Form";

function Statuses() {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		scroll();
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `dashboardStatusesInit`,
			q: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({ ...auth });
				setDetails({ ...json });

				setNotice(
					json.needsAttention
						? {
								type: "notice",
								text: "Please make sure all participants have completed their registration details. Participants without a details green check are not fully registered.",
						  }
						: {}
				);

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleSuccess = (text) => {
		setNotice({ type: "success", text });
	};

	return (
		<>
			<h3 className="subheading">
				All Participant Statuses / Update Group Assignments
			</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						{notice.type && <Notice data={notice} />}

						<p className="p1">
							Please see below for all of your current participant statuses for
							each event. Click a name to view additional details, remove from
							an event or resend a pending invitation.
						</p>

						<p className="p1" style={{ marginTop: "15px" }}>
							If your event contains spots for more than 1 group, you can use
							the update group assignments function to create your desired
							foursomes.
						</p>

						{["wed", "thu"].map((event) => {
							return (
								<Form
									key={event}
									data={details[event]}
									handleSuccess={handleSuccess}
								/>
							);
						})}
					</div>

					<div id="right-column">
						<Nav on={4} user={user} />
					</div>
				</div>
			)}
		</>
	);
}

export default Statuses;
