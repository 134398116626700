import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./styles.css";

const Button = ({ name, icon = false, handleClick }) => {
	return (
		<div className="button">
			<button type="button" onClick={handleClick}>
				<span>
					{icon && <FontAwesomeIcon icon={icon} size="lg" />}
					<span>{name}</span>
				</span>
			</button>
		</div>
	);
};

export default Button;
