import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL } from "../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faChevronCircleRight,
	faCircle,
	faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";

import Select from "../../shared/Select";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

function ResponseForm({
	id,
	registrationName,
	fname,
	lname,
	events,
	setResponseSubmitted,
}) {
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));

		console.log(name);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=guestResponse`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("id", id);
		formData.append("events", JSON.stringify(events.map((item) => item.id)));
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setResponseSubmitted(true);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<p className="p1">
				<strong>{registrationName}</strong> has invited you to participate in
				the 2024 Pro-Ams under their sponsorship. Please accept or decline the
				event invitation(s) below.
			</p>

			<form
				ref={formElement}
				method="post"
				action="/"
				onSubmit={(e) => handleSubmit(e)}
				style={{ marginTop: "25px" }}
			>
				<h4>{`Event invitation(s) for ${fname} ${lname}`}</h4>

				<table className="response">
					<thead>
						<tr>
							<th>Select</th>
							<th>Event Name</th>
						</tr>
					</thead>

					<tbody>
						{events.map((item) => {
							return (
								<tr key={item.id}>
									<td>
										<Select
											name={item.id}
											label=""
											req={true}
											value=""
											options={[
												{ value: "", name: "--" },
												{
													value: "accept",
													name: "Accept",
												},
												{
													value: "decline",
													name: "Decline",
												},
											]}
											update={handleUpdate}
											errors={errors}
										/>
									</td>
									<td
										className={
											names[item.id] === "accept"
												? "left one"
												: names[item.id] === "decline"
												? "left zero"
												: "left four"
										}
									>
										<div
											className={
												names[item.id] === "accept"
													? "flex one"
													: names[item.id] === "decline"
													? "flex zero"
													: "flex four"
											}
										>
											{names[item.id] === "accept" ? (
												<FontAwesomeIcon icon={faCircleCheck} size="2x" />
											) : names[item.id] === "decline" ? (
												<FontAwesomeIcon icon={faCircleXmark} size="2x" />
											) : (
												<FontAwesomeIcon icon={faCircle} size="2x" />
											)}
											<div>
												<h4>{item.name}</h4>
												<p>{item.description}</p>
											</div>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>

				<div className="msg-submit">
					{msg.type && <Msg data={msg} />}

					<div
						className={
							msg.type === "working" || msg.type === "success" ? "hidden" : ""
						}
					>
						<Submit
							name="Submit Invitation Response"
							icon={faChevronCircleRight}
						/>
					</div>
				</div>
			</form>
		</>
	);
}

export default ResponseForm;
