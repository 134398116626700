import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
	API_URL_PROAM as API_URL,
	getValFromLS,
	setValToLS,
	scroll,
} from "../../utils";

import Alert from "../../shared/Alert";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import Nav from "../Nav";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function DashContact() {
	const [user, setUser] = useState({});
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const formElement = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `dashboardContactInit`,
			id: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({ ...auth });
				setNames({ ...json });
				setAlert({});
				scroll();
			} else {
				setAlert({
					type: json.resp === 2 ? "notice" : "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=dashboardContact`);
		const formData = new FormData(formElement.current);
		formData.append("auth", getValFromLS("proam-dash", true));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				let auth = JSON.parse(getValFromLS("proam-dash", true));
				auth.fname = json.fname;
				auth.lname = json.lname;
				auth.email = json.email;
				auth.organization = json.organization;
				setValToLS("proam-dash", JSON.stringify(auth), true);

				navigate("/dashboard", {
					state: {
						data: { type: "success", text: json.text },
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Dashboard Contact Information</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						<p className="p1">
							Use this form to make any updates to your contact information
							(shown to your Pro-Am participants).
						</p>

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<div className="form-row-2">
								<Input
									type="text"
									label="First Name"
									req={true}
									name="fname"
									value={names.fname}
									errors={errors}
									autocomplete="given-name"
								/>

								<Input
									type="text"
									label="Last Name"
									req={true}
									name="lname"
									value={names.lname}
									errors={errors}
									autocomplete="family-name"
								/>
							</div>

							<div className="form-row-2">
								<Input
									type="text"
									label="Email Address"
									req={true}
									name="email"
									value={names.email}
									errors={errors}
									autocomplete="email"
								/>

								<Input
									type="text"
									label="Phone"
									req={true}
									name="phone"
									value={names.phone}
									errors={errors}
									autocomplete="tel"
								/>
							</div>

							<div className="form-row-2">
								<Input
									type="text"
									label="Organization"
									req={true}
									name="organization"
									value={names.organization}
									errors={errors}
									autocomplete="organization"
								/>

								<div className="empty" />
							</div>

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" || msg.type === "success"
											? "hidden"
											: ""
									}
								>
									<Submit
										name="Update Contact Info"
										icon={faChevronCircleRight}
									/>
								</div>
							</div>
						</form>
					</div>

					<div id="right-column">
						<Nav on={2} user={user} />
					</div>
				</div>
			)}
		</>
	);
}

export default DashContact;
