import { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS, scroll } from "../../utils";

import Alert from "../../shared/Alert";
import Radios from "../../shared/Radios";
import Select from "../../shared/Select";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import DownloadReport from "./DownloadReport";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function AdminCsv() {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [report, setReport] = useState({
		generated: false,
	});
	const [names, setNames] = useState({
		type: "",
	});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const formElement = useRef();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		scroll();
		const auth = getValFromLS("proam-admin", true)
			? JSON.parse(getValFromLS("proam-admin", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `adminCsvInit`,
			id: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({ ...auth });
				setDetails({ ...json });
				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		if (name === "date") {
			const found = details.dates.find((element) => element.id === value);
			const array = found.val.split("-");

			setNames((names) => ({
				...names,
				[name]: value ? value : "",
			}));

			formElement.current.start.value = array[0];
			formElement.current.end.value = array[1];
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=adminCsv`);
		const formData = new FormData(formElement.current);
		formData.append("type", names.type);
		formData.append("id", user.id);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({
					type: "success",
					text: json.text,
				});
				setReport({ ...json.report });
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleReset = () => {
		setNames({ type: "" });
		if (formElement.current.proam) {
			formElement.current.proam.value = "";
		}
		if (formElement.current.date) {
			formElement.current.date.value = "";
			formElement.current.start.value = "";
			formElement.current.end.value = "";
		}

		setReport({ generated: false });
		setMsg({});
	};

	return (
		<>
			<h3 className="subheading">Pro-Am Registration Admin</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						<p className="p1">
							Use this form to create .csv export reports for Pro-Am
							registration data.
						</p>

						<p className="p1" style={{ marginTop: "15px" }}>
							Questions or problems? Please email{" "}
							<a href="mailto:kylan@qnewmedia.com">kylan@qnewmedia.com</a>.
						</p>

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<div className="form-row-1">
								<Radios
									label="Select Report Type:"
									req={true}
									data={details.types}
									value={names.type}
									click={handleUpdate}
								/>
							</div>

							{(names.type === "participant" || names.type === "teams") && (
								<div className="form-row-2">
									<Select
										name="proam"
										label="Pro-Am"
										req={true}
										value={""}
										options={[].concat(
											details.proams.map((item) => {
												return {
													value: item.id,
													name: item.name,
												};
											})
										)}
										errors={errors}
										autocomplete="off"
									/>

									<div className="empty" />
								</div>
							)}

							{(names.type === "" || names.type === "registration") && (
								<div className="form-row-2">
									<Select
										name="date"
										label="Date Range"
										req={false}
										value={""}
										options={[].concat(
											details.dates.map((item) => {
												return {
													value: item.id,
													name: item.name,
												};
											})
										)}
										errors={errors}
										autocomplete="off"
										update={handleUpdate}
									/>

									<div className="empty" />
								</div>
							)}

							{(names.type === "" || names.type === "registration") && (
								<div className="form-row-2">
									<Input
										type="text"
										label="Start Date"
										req={true}
										name="start"
										value={""}
										errors={errors}
										autocomplete="off"
									/>

									<Input
										type="text"
										label="End Date"
										req={true}
										name="end"
										value={""}
										errors={errors}
										autocomplete="off"
									/>
								</div>
							)}

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" || msg.type === "success"
											? "hidden"
											: ""
									}
								>
									<Submit name="Generate Report" icon={faChevronCircleRight} />
								</div>
							</div>

							{report.generated && (
								<DownloadReport report={report} handleReset={handleReset} />
							)}
						</form>
					</div>

					<div id="right-column"></div>
				</div>
			)}
		</>
	);
}

export default AdminCsv;
