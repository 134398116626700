import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

const Radios = ({ label, req, data, value, click, border = true }) => {
	return (
		<div className={border ? "radios border" : "radios"}>
			<label className={!label ? "hidden" : ""}>
				{`${label}`} {req ? <span className="req">*</span> : null}
			</label>

			<div>
				{data.map((item, index) => {
					return (
						<button
							type="button"
							key={index}
							onClick={() => click(item.name, item.value)}
						>
							<div>
								<FontAwesomeIcon
									icon={item.value === value ? faCircleCheck : faCircle}
								/>
								<span>{item.label}</span>
							</div>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default Radios;
