import Input from "../Input";

function ContactFields({ fname, lname, email, phone, company, title, errors }) {
	return (
		<>
			<div className="form-row-2">
				<Input
					type="text"
					label="First Name"
					req={true}
					name="fname"
					value={fname}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="Last Name"
					req={true}
					name="lname"
					value={lname}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div className="form-row-2">
				<Input
					type="text"
					label="Email Address"
					req={true}
					name="email"
					value={email}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="Cell Phone"
					req={true}
					name="phone"
					value={phone}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div className="form-row-2">
				<Input
					type="text"
					label="Company"
					req={true}
					name="company"
					value={company}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="Professional Title"
					req={true}
					name="title"
					value={title}
					errors={errors}
					autocomplete="off"
				/>
			</div>
		</>
	);
}

export default ContactFields;
