import { useEffect } from "react";

import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleExclamation,
	faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";

function Notice({ data }) {
	const { type, text } = data;
	const icon =
		type === "success"
			? faCircleCheck
			: type === "notice"
			? faCircleExclamation
			: faCircleXmark;

	return (
		<div className={`notice ${data.type}`}>
			<FontAwesomeIcon icon={icon} size="lg" />
			<span>{text}</span>
		</div>
	);
}

export default Notice;
