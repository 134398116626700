import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleXmark,
	faCircleExclamation,
	faHourglass,
	faClock,
	faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";

function StatusesTable({ id, wed, thu, handleRemove }) {
	return (
		<table style={{ marginTop: "25px" }}>
			<thead>
				<tr>
					<th colSpan="3" className="hdg">
						Invitation Status
					</th>
				</tr>
				<tr>
					<td>Event</td>
					<td className="left">Status</td>
					<td>Remove</td>
				</tr>
			</thead>
			<tbody>
				<Tr
					data={{
						...wed,
						event: "wed",
						id,
					}}
					remove={handleRemove}
				/>
				<Tr
					data={{
						...thu,
						event: "thu",
						id,
					}}
					remove={handleRemove}
				/>
			</tbody>
		</table>
	);
}

export default StatusesTable;

function Tr({ data, remove }) {
	return (
		<tr className={data.status === 3 ? "hidden" : ""}>
			<td>{data.name}</td>
			<td
				className={
					data.status === 1
						? "left one"
						: data.status === 0
						? "left zero"
						: data.status === 4
						? "left three"
						: "left two"
				}
			>
				<div className="flex">
					{data.status === 1 ? (
						<FontAwesomeIcon icon={faCircleCheck} />
					) : data.status === 0 ? (
						<FontAwesomeIcon icon={faCircleXmark} />
					) : data.status === 2 ? (
						<FontAwesomeIcon icon={faHourglass} />
					) : data.status === 4 ? (
						<FontAwesomeIcon icon={faClock} />
					) : (
						<FontAwesomeIcon icon={faCircleExclamation} />
					)}

					<span>{data.text}</span>
				</div>
			</td>
			<td>
				{(data.status === 1 || data.status === 2) && (
					<button
						type="button"
						title="Remove from spot"
						onClick={(e) => remove(e, data.id, data.event)}
						className="naked"
						style={{ color: "var(--error)" }}
					>
						<FontAwesomeIcon icon={faTrashAlt} />
					</button>
				)}
			</td>
		</tr>
	);
}
