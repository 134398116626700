import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

const Checkboxes = ({ label, req, data, values, click }) => {
	return (
		<div className="checkboxes">
			<label className={!label ? "hidden" : ""}>
				{`${label}`} {req ? <span className="req">*</span> : null}
			</label>

			<div>
				{data.map((item) => {
					const checked = values.includes(item.name);

					return (
						<button
							type="button"
							key={item.name}
							onClick={() => click(item.name, !checked, item.value)}
							disabled={item.disabled}
						>
							<div>
								<FontAwesomeIcon icon={checked ? faSquareCheck : faSquare} />

								<span>{item.label ? item.label : item.value}</span>
							</div>
						</button>
					);
				})}
			</div>
		</div>
	);
};

export default Checkboxes;
