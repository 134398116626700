import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./index.css";

import Alert from "./shared/Alert/index";

import Contact from "./Contact";
import PlayerField from "./PlayerField";

import router from "./router";

/*  uncomment for testing locally
// contact, playerfield, proam
window.tgcformsSettings = {
	id: "proam",
};*/

if (document.getElementById("tgcforms-root")) {
	const root = ReactDOM.createRoot(document.getElementById("tgcforms-root"));

	let element = (
		<Alert data={{ type: "error", text: "An error has occurred." }} />
	);

	if (window.tgcformsSettings) {
		const { id } = window.tgcformsSettings;

		if (id === "contact") {
			element = <Contact />;
		} else if (id === "playerfield") {
			element = <PlayerField />;
		} else if (id === "proam") {
			element = <RouterProvider router={router} />;
		}
	}

	root.render(<React.StrictMode>{element}</React.StrictMode>);
}
