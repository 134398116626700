import { createBrowserRouter, Navigate } from "react-router-dom";

import RedirectToPAPage from "./ProamDash/RedirectToPAPage";

import Dashboard from "./ProamDash/Home";
import DashRegister from "./ProamDash/Register";
import DashAutoSignin from "./ProamDash/AutoSignin";
import DashContact from "./ProamDash/Contact";
import DashInvite from "./ProamDash/Invite";
import DashGuest from "./ProamDash/Guest";
import DashGuestEdit from "./ProamDash/GuestEdit";
import DashStatuses from "./ProamDash/Statuses";

import Guest from "./ProamGuest/Home";
import GuestSignin from "./ProamGuest/Signin";

import AdminSignin from "./ProamAdmin/Signin";
import AdminCsv from "./ProamAdmin/Csv";

/*
 

  import DashSignin from "./Dashboard/Signin";




  import DashGuestUpload from "./Dashboard/GuestUpload";
 


  import GuestSignin from "./Guest/Signin";
  import GuestUpload from "./Guest/Upload";

  

		

		{
			path: "/dashboard/signin",
			element: <DashSignin />,
		},

		

		{
			path: "/dashboard/guest/:str/upload",
			element: <DashGuestUpload />,
		},
		
	
		
		{
			path: "/guest/:str/upload",
			element: <GuestUpload />,
		},
		
*/

const router = createBrowserRouter(
	[
		{
			path: "/",
			element: <RedirectToPAPage />,
		},
		{
			path: "/dashboard",
			element: <Dashboard />,
		},
		{
			path: "/dashboard/register/:str",
			element: <DashRegister />,
		},
		{
			path: "/dashboard/auto-signin/:str",
			element: <DashAutoSignin />,
		},
		{
			path: "/dashboard/contact",
			element: <DashContact />,
		},
		{
			path: "/dashboard/invite",
			element: <DashInvite />,
		},
		{
			path: "/dashboard/guest/:str",
			element: <DashGuest />,
		},
		{
			path: "/dashboard/guest/:str/edit",
			element: <DashGuestEdit />,
		},
		{
			path: "/dashboard/statuses",
			element: <DashStatuses />,
		},
		{
			path: "/guest/:str",
			element: <Guest />,
		},
		{
			path: "/guest/signin",
			element: <GuestSignin />,
		},
		{
			path: "/admin",
			element: <Navigate to="/admin/signin" />,
		},
		{
			path: "/admin/signin",
			element: <AdminSignin />,
		},
		{
			path: "/admin/csv",
			element: <AdminCsv />,
		},
	],
	{
		basename: "/proam-registration",
	}
);

export default router;
