import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
//import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, setValToLS } from "../../utils";

import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function AdminSignin() {
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});

	const formElement = useRef();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=adminSignin`);
		const formData = new FormData(formElement.current);

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const auth = {
					id: json.id,
					fname: json.fname,
					email: json.email,
				};
				setValToLS("proam-admin", JSON.stringify(auth), true);

				navigate("/admin/csv");
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Pro-Am Registration Admin</h3>
			<div className="left-right-grid">
				<div id="left-column">
					<p className="p1">
						Please enter your email address and password for access to the
						Pro-Am Registration administration area.
					</p>

					<p className="p1" style={{ marginTop: "15px" }}>
						Questions or problems? Please email{" "}
						<a href="mailto:kylan@qnewmedia.com">kylan@qnewmedia.com</a>.
					</p>

					<form
						method="post"
						action="/"
						onSubmit={(e) => handleSubmit(e)}
						ref={formElement}
					>
						<div className="form-row-2">
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value=""
								errors={errors}
								autocomplete="email"
							/>

							<Input
								type="password"
								label="Password"
								req={true}
								name="pwd"
								value=""
								errors={errors}
								autocomplete="off"
							/>
						</div>

						<div className="msg-submit">
							{msg.type && <Msg data={msg} />}

							<div
								className={
									msg.type === "working" || msg.type === "success"
										? "hidden"
										: ""
								}
							>
								<Submit name="Submit Sign In" icon={faChevronCircleRight} />
							</div>
						</div>
					</form>
				</div>

				<div id="right-column"></div>
			</div>
		</>
	);
}

export default AdminSignin;
