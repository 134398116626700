import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleXmark,
	faCircleExclamation,
} from "@fortawesome/pro-light-svg-icons";

function GuestDetailsTable2({ details }) {
	return (
		<table style={{ marginTop: "25px" }}>
			<thead>
				<tr>
					<th colSpan="2" className="hdg">
						{`${details.fname} ${details.lname}`}
					</th>
				</tr>
				<tr>
					<td style={{ width: "30%" }}>Event</td>
					<td className="left">Invitation Status</td>
				</tr>
			</thead>

			<tbody>
				<Tr
					data={{
						...details.invite.wed,
						id: details.id,
					}}
				/>
				<Tr
					data={{
						...details.invite.thu,
						id: details.id,
					}}
				/>
			</tbody>
		</table>
	);
}

export default GuestDetailsTable2;

function Tr({ data }) {
	return (
		<tr className={data.status === 3 || !data.text ? "hidden" : ""}>
			<td>{data.name}</td>
			<td
				className={
					data.status === 1
						? "left one"
						: data.status === 0
						? "left zero"
						: "left two"
				}
			>
				<div className="flex">
					{data.status === 1 ? (
						<FontAwesomeIcon icon={faCircleCheck} />
					) : data.status === 0 ? (
						<FontAwesomeIcon icon={faCircleXmark} />
					) : (
						<FontAwesomeIcon icon={faCircleExclamation} />
					)}

					<span>{data.text}</span>
				</div>
			</td>
		</tr>
	);
}
