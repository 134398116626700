import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS } from "../../utils";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

import Select from "../../shared/Select";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

function ResendForm({ email, id }) {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [msg, setMsg] = useState({});

	const formElement = useRef();

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=dashboardGuestResend`);
		let formData = new FormData(formElement.current);
		formData.append("q", id);
		formData.append("auth", getValFromLS("proam-dash", true));
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				formElement.current.reset();
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<form
			ref={formElement}
			method="post"
			action="/"
			onSubmit={(e) => handleSubmit(e)}
			style={{
				marginTop: "25px",
				border: "1px solid var(--border)",
				padding: "15px",
			}}
		>
			<h3>Resend Invitation</h3>

			<div className="form-row-2">
				<Select
					name="recipient"
					label="Select Recipient"
					req={true}
					value={""}
					options={[
						{ value: "", name: "--" },
						{
							value: `${email}`,
							name: `Original Email (${email})`,
						},
						{ value: "other", name: "Alternate Email" },
					]}
					update={handleUpdate}
					errors={errors}
				/>

				{names.recipient === "other" ? (
					<Input
						type="text"
						label="Enter Other Email"
						req={true}
						name="email"
						value={names.email}
						errors={errors}
						autocomplete="off"
					/>
				) : (
					<div className="empty" />
				)}
			</div>

			<div className="msg-submit" style={{ marginBottom: "5px" }}>
				{msg.type && <Msg data={msg} />}

				<div
					className={
						msg.type === "working" || msg.type === "success" ? "hidden" : ""
					}
				>
					<Submit
						name="Resend Participant Invitation"
						icon={faChevronCircleRight}
					/>
				</div>
			</div>
		</form>
	);
}

export default ResendForm;
