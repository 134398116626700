import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, setValToLS } from "../../utils";

import Alert from "../../shared/Alert";

function AutoSignin() {
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const { str } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const url = new URL(`${API_URL}/?f=dashboardAutoSigninInit`);
		const params = {
			q: str || "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const auth = {
					id: json.id,
					fname: json.fname,
					lname: json.lname,
					email: json.email,
					organization: json.organization,
				};
				setValToLS("proam-dash", JSON.stringify(auth), true);

				navigate("/dashboard");
			} else {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<Alert data={alert} />
		</>
	);
}

export default AutoSignin;
