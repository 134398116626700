import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPencilSquare,
	faEnvelope,
	faTable,
} from "@fortawesome/pro-light-svg-icons";

import "./styles.css";

function Tiles() {
	return (
		<div className="tiles">
			<Link to="/dashboard/contact">
				<div>
					<FontAwesomeIcon icon={faPencilSquare} size="2x" />
					<span>Update Contact Info</span>
				</div>
				<small>
					Update your contact information that is shown to your Pro-Am
					participants.
				</small>
			</Link>

			<Link to="/dashboard/invite">
				<div>
					<FontAwesomeIcon icon={faEnvelope} size="2x" />
					<span>Add / Invite Participant</span>
				</div>
				<small>
					Place or invite participants to spots in your Pro-Am events.
				</small>
			</Link>

			<Link to="/dashboard/statuses">
				<div>
					<FontAwesomeIcon icon={faTable} size="2x" />
					<span>View All Participants</span>
				</div>
				<small>Snapshot view of all your participant statuses by event.</small>
			</Link>
		</div>
	);
}

export default Tiles;
