import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL } from "../../utils";

import Alert from "../../shared/Alert";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Signin() {
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert] = useState({});

	const formElement = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=guestSignin`);
		let formData = new FormData(formElement.current);
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				formElement.current.reset();
				setMsg({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Pro-Am Participant Access</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						<p className="p1">
							If you are participant that has misplaced your invite email (or
							perhaps you here added directly by your Pro-Am contact) and need
							to update your details, please enter your email address and we
							will send you an access link.
						</p>

						<p className="p1" style={{ marginTop: "15px" }}>
							Questions? Please call Jack Harris at +1 (415) 847-0012 or email{" "}
							<a href="mailto:jack@thegallericlassic.com">
								jack@thegallericlassic.com
							</a>
							.
						</p>

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<div className="form-row-2">
								<Input
									type="text"
									label="Email Address"
									req={true}
									name="email"
									value=""
									errors={errors}
									autocomplete="email"
								/>

								<div className="empty" />
							</div>

							<p style={{ marginTop: "10px" }}>
								Please enter the email address that was used by your contact to
								add / invite you to event(s).
							</p>

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" || msg.type === "success"
											? "hidden"
											: ""
									}
								>
									<Submit name="Submit Request" icon={faChevronCircleRight} />
								</div>
							</div>
						</form>
					</div>

					<div id="right-column"></div>
				</div>
			)}
		</>
	);
}

export default Signin;
