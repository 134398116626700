function WedDetails() {
	return (
		<div>
			<p>Wednesday Pro-Am</p>

			<ul>
				<li>Date: Wednesday, March 27</li>
				<li>Golf Course: Mission Hills Country Club</li>
				<li>Tee times beginning at 7:00 am</li>
			</ul>
		</div>
	);
}

export default WedDetails;
