import { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS, scroll } from "../../utils";

import Alert from "../../shared/Alert";
import Notice from "../../shared/Notice";
import GuestDetailsTable2 from "../../shared/GuestDetailsTable2";
import GuestDetailsForm from "../../shared/GuestDetailsForm";

import Nav from "../Nav";

function GuestEdit() {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [notice, setNotice] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const { str } = useParams();
	const location = useLocation();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		scroll();
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `guestInit`,
			id: str ? str : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (!auth.id) {
				setAlert({
					type: "error",
					text: "An error has occurred.",
				});
			} else if (json && (json.resp === 1 || json.resp === 2)) {
				setUser({ ...auth });
				setDetails({ ...json });

				if (location?.state?.data) {
					setNotice({
						type: location.state.data.type,
						text: location.state.data.text,
					});
				} else if (
					(json.invite.wed.status !== 3 || json.invite.thu.status !== 3) &&
					!json.address
				) {
					setNotice({
						type: "notice",
						text: "Participant details need to be completed, please use the form below.",
					});
				}

				setAlert({});
			} else {
				setAlert({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Participant Details / Invitation Status</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						{notice.type && <Notice data={notice} />}

						<p className="p1">
							Use the form below to complete the required participant
							registration details on your guest’s behalf.
						</p>

						<GuestDetailsTable2 details={details} />
						<GuestDetailsForm details={details} back2dash={true} />
					</div>

					<div id="right-column">
						<Nav on={0} user={user} />
					</div>
				</div>
			)}
		</>
	);
}

export default GuestEdit;
