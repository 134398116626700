import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS, scroll } from "../../utils";

import Alert from "../../shared/Alert";
import Radios from "../../shared/Radios";
import Checkboxes from "../../shared/Checkboxes";
import Input from "../../shared/Input";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

import Nav from "../Nav";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function DashInvite() {
	const [user, setUser] = useState({});
	const [details, setDetails] = useState({});
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		method: "",
	});
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	const initRef = useRef(false);
	const formElement = useRef();
	const navigate = useNavigate();

	useEffect(() => {
		if (initRef.current) return;
		initRef.current = true;
		init();
	});

	const init = async () => {
		const auth = getValFromLS("proam-dash", true)
			? JSON.parse(getValFromLS("proam-dash", true))
			: {};

		const url = new URL(`${API_URL}/`);
		const params = {
			f: `dashboardInviteInit`,
			id: auth.id ? auth.id : "",
			x: uuidv4(),
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setUser({ ...auth });
				setDetails({
					events1: json.events1,
					events2: json.events2,
				});
				setAlert({});
				scroll();
			} else {
				setAlert({
					type: json.resp === 2 ? "notice" : "error",
					text: json.text,
				});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleCheckboxes = (name, checked, value) => {
		let newValue = "";
		if (checked) {
			newValue = value;
		}
		setNames((names) => ({ ...names, [name]: newValue }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=dashboardInvite`);
		const formData = new FormData(formElement.current);
		Object.entries(names).forEach(([key, value]) => {
			formData.append(key, value);
		});
		formData.append("auth", getValFromLS("proam-dash", true));
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				const next =
					names.method === "add" || names.method === "invite"
						? `/dashboard`
						: `/dashboard/guest/${json.guestIdEncrypted}/edit`;

				navigate(next, {
					state: {
						data: { type: "success", text: json.text },
					},
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			<h3 className="subheading">Add Participant / Send Email Invite</h3>

			{alert.type ? (
				<Alert data={alert} />
			) : (
				<div className="left-right-grid">
					<div id="left-column">
						<p className="p1">
							Use this form to add and invite participants to Pro-Am events
							under your Sponsorship.
						</p>

						<form
							method="post"
							action="/"
							onSubmit={(e) => handleSubmit(e)}
							ref={formElement}
						>
							<div className="form-row-1">
								<Radios
									label="Please Select:"
									req={true}
									data={[
										{
											name: "method",
											value: "add",
											label: "Immediately add a participant to event(s)",
										},
										{
											name: "method",
											value: "invite",
											label: "Send email invite to a guest",
										},
										{
											name: "method",
											value: "myself",
											label: "Add yourself as a participant",
										},
									]}
									value={names.method}
									click={handleUpdate}
								/>
							</div>

							{names.method !== "myself" && (
								<>
									<h3>Participant Information</h3>

									<div className="form-row-2">
										<Input
											type="text"
											label="First Name"
											req={true}
											name="fname"
											value={names.fname}
											errors={errors}
											autocomplete="given-name"
										/>

										<Input
											type="text"
											label="Last Name"
											req={true}
											name="lname"
											value={names.lname}
											errors={errors}
											autocomplete="family-name"
										/>
									</div>

									<div className="form-row-2" style={{ marginBottom: "25px" }}>
										<Input
											type="text"
											label="Email Address"
											req={true}
											name="email"
											value=""
											errors={errors}
											autocomplete="email"
										/>

										<Input
											type="text"
											label="Confirm Email Address"
											req={true}
											name="confirm"
											value=""
											errors={errors}
											autocomplete="confirm"
										/>
									</div>
								</>
							)}

							<h3>Available Events</h3>

							<div className="form-row-1">
								<Checkboxes
									label=""
									req={false}
									data={details.events1.concat(details.events2)}
									values={createValuesArray(names)}
									click={handleCheckboxes}
								/>
							</div>

							<div className="msg-submit">
								{msg.type && <Msg data={msg} />}

								<div
									className={
										msg.type === "working" || msg.type === "success"
											? "hidden"
											: ""
									}
								>
									<Submit
										name="Add / Invite Participant"
										icon={faChevronCircleRight}
									/>
								</div>
							</div>
						</form>
					</div>

					<div id="right-column">
						<Nav on={3} user={user} />
					</div>
				</div>
			)}
		</>
	);
}

export default DashInvite;

function createValuesArray(names) {
	let array = [];
	if (names.wed) {
		array.push("wed");
	}
	if (names.thu) {
		array.push("thu");
	}

	return array;
}
