import Input from "../Input";
import Select from "../Select";
import Checkbox from "../Checkbox";

function GolfFields({
	handicap,
	ghin,
	noGhin,
	rental,
	shirt,
	shoe,
	clubName,
	clubPhone,
	clubAddress,
	handleUpdate,
	errors,
}) {
	return (
		<>
			<div className="form-row-2">
				<Input
					type="text"
					label="Handicap"
					req={true}
					name="handicap"
					value={handicap}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="GHIN"
					req={false}
					name="ghin"
					value={ghin}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div>
				<Checkbox
					name="noGhin"
					label="I don’t have a GHIN (please enter your Club information below to confirm handicap)."
					req={false}
					checked={noGhin ? true : false}
					click={handleUpdate}
				/>
			</div>

			<div className="form-row-2">
				<Input
					type="text"
					label="Club Name"
					req={noGhin ? true : false}
					name="clubName"
					value={clubName}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="Club Phone"
					req={noGhin ? true : false}
					name="clubPhone"
					value={clubPhone}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div className="form-row-1">
				<Input
					type="text"
					label="Club City / State"
					req={noGhin ? true : false}
					name="clubAddress"
					value={clubAddress}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div className="form-row-2">
				<Select
					name="rental"
					label="Will you need rental clubs?"
					req={true}
					value={rental}
					options={[
						{
							value: "",
							name: "--",
						},
						{
							value: "No, I will bring my own clubs",
							name: "No, I will bring my own clubs",
						},

						{
							value: "Yes, Men’s Right Hand",
							name: "Yes, Men’s Right Hand",
						},

						{
							value: "Yes, Men’s Left Hand",
							name: "Yes, Men’s Left Hand",
						},

						{
							value: "Yes, Women’s Right Hand",
							name: "Yes, Women’s Right Hand",
						},

						{
							value: "Yes, Women’s Left Hand",
							name: "Yes, Women’s Left Hand",
						},
					]}
					errors={errors}
				/>

				<div className="empty" />
			</div>

			<div className="form-row-2">
				<Select
					name="shirt"
					label="Shirt Size"
					req={true}
					value={shirt}
					options={[
						{
							value: "",
							name: "--",
						},
						{
							value: "Small",
							name: "Small",
						},

						{
							value: "Medium",
							name: "Medium",
						},

						{
							value: "Large",
							name: "Large",
						},

						{
							value: "X-Large",
							name: "X-Large",
						},

						{
							value: "XX-Large",
							name: "XX-Large",
						},
					]}
					errors={errors}
				/>

				<Select
					name="shoe"
					label="Shoe Size"
					req={true}
					value={shoe}
					options={[
						{
							value: "",
							name: "--",
						},
						{
							value: "8",
							name: "8",
						},

						{
							value: "8.5",
							name: "8.5",
						},
						{
							value: "9",
							name: "9",
						},

						{
							value: "9.5",
							name: "9.5",
						},
						{
							value: "10",
							name: "10",
						},

						{
							value: "10.5",
							name: "10.5",
						},
						{
							value: "11",
							name: "11",
						},

						{
							value: "11.5",
							name: "11.5",
						},
						{
							value: "12",
							name: "12",
						},

						{
							value: "12.5",
							name: "12.5",
						},
						{
							value: "13",
							name: "13",
						},
						{
							value: "14",
							name: "14",
						},
						{
							value: "15",
							name: "15",
						},
					]}
					errors={errors}
				/>
			</div>
		</>
	);
}

export default GolfFields;
