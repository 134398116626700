import { Link, useNavigate } from "react-router-dom";

import { setValToLS } from "../../utils";

import Button from "../../shared/Button";

import "./styles.css";

function Nav({ on, user }) {
	const navigate = useNavigate();

	const handleSignout = () => {
		setValToLS("proam-dash", null, true);
		navigate("/");
	};

	return (
		<>
			{user && (
				<div className="steps-container">
					<h4 style={{ marginBottom: "0" }}>{`Welcome, ${user.fname}`}</h4>
					<h4 style={{ marginTop: "5px" }}>{`${user.organization}`}</h4>

					<Button name="Sign Out" handleClick={handleSignout} />

					<ol>
						{[
							{
								id: 1,
								name: "Dashboard Home",
								link: "/dashboard",
							},
							{
								id: 2,
								name: "Update Contact Info",
								link: "/dashboard/contact",
							},
							{
								id: 3,
								name: "Add / Invite Participant",
								link: "/dashboard/invite",
							},
							{
								id: 4,
								name: "View All Participants",
								link: "/dashboard/statuses",
							},
						].map((item) => {
							return (
								<li className={item.id === on ? "selected" : ""} key={item.id}>
									<Link to={item.link}>{item.name}</Link>
								</li>
							);
						})}
					</ol>
				</div>
			)}

			<div className="blocktext" style={{ marginTop: "20px" }}>
				Questions? Please reach out to Jack Harris,{" "}
				<a href="jack@thegallericlassic.com">jack@thegallericlassic.com</a> or
				+1 (415) 847-0012.
			</div>
		</>
	);
}

export default Nav;
