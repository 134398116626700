import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS } from "../../utils";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

import Msg from "../Msg";
import Submit from "../Submit";

import ContactFields from "./ContactFields";
import AddressFields from "./AddressFields";
import GolfFields from "./GolfFields";
import PairingsPartyFields from "./PairingsPartyFields";
//import TermsFields from "./TermsFields";

function GuestDetailsForm({ details, back2dash = false }) {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({
		fname: details.fname,
		lname: details.lname,
		email: details.email,
		phone: details.phone || "",
		company: details.company || "",
		title: details.title || "",

		address: details.address || "",
		city: details.city || "",
		st: details.st || "",
		zip: details.zip || "",

		handicap: details.handicap || "",
		ghin: details.ghin || "",
		noGhin: details.noGhin || false,
		rental: details.rental || "",
		shirt: details.shirt || "",
		shoe: details.shoe || "",
		clubName: details.clubName || "",
		clubPhone: details.clubPhone || "",
		clubAddress: details.clubAddress || "",

		signature: details.signature || "",

		attending: details.attending || "",
		guest: details.guest || "",
	});
	const [msg, setMsg] = useState({});

	const formElement = useRef();
	const navigate = useNavigate();

	const handleUpdate = (name, value) => {
		if (name === "noGhin") {
			const newValue = !value;

			setNames((names) => ({ ...names, [name]: newValue }));
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=guestDetails`);
		const formData = new FormData(formElement.current);
		formData.append("noGhin", names.noGhin ? "1" : "0");
		formData.append("attending", names.attending ? names.attending : "");
		formData.append("wedStatus", details.invite.wed.status);
		formData.append("thuStatus", details.invite.thu.status);
		formData.append("id", details.id);
		formData.append(
			"auth",
			getValFromLS("proam-dash", true) ? getValFromLS("proam-dash", true) : "{}"
		);
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				if (back2dash) {
					navigate(`/dashboard`, {
						state: { message: { type: "success", text: json.text } },
					});
				} else {
					setMsg({
						type: "success",
						text: json.text,
					});
				}
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<form
			ref={formElement}
			method="post"
			action="/"
			onSubmit={(e) => handleSubmit(e)}
			style={{ marginTop: "25px" }}
		>
			<h3>Contact Information</h3>

			<ContactFields
				fname={names.fname}
				lname={names.lname}
				email={names.email}
				phone={names.phone}
				company={names.company}
				title={names.title}
				errors={errors}
			/>

			<h3
				style={{
					marginTop: "20px",
				}}
			>
				Mailing Address
			</h3>

			<AddressFields
				address={names.address}
				city={names.city}
				st={names.st}
				zip={names.zip}
				errors={errors}
			/>

			<h3
				style={{
					marginTop: "20px",
				}}
			>
				Golf Information
			</h3>

			<GolfFields
				handicap={names.handicap}
				ghin={names.ghin}
				noGhin={names.noGhin}
				rental={names.rental}
				shirt={names.shirt}
				shoe={names.shoe}
				clubName={names.clubName}
				clubPhone={names.clubPhone}
				clubAddress={names.clubAddress}
				handleUpdate={handleUpdate}
				errors={errors}
			/>

			<h3
				style={{
					marginTop: "25px",
				}}
			>
				Pairings Party Attendance
			</h3>

			<PairingsPartyFields
				attending={names.attending}
				guest={names.guest}
				handleUpdate={handleUpdate}
				errors={errors}
			/>

			{/*<h3>Terms & Conditions</h3>

			<TermsFields
				signature={names.signature}
				handleUpdate={handleUpdate}
				errors={errors}
			/>

		*/}

			<div className="msg-submit">
				{msg.type && <Msg data={msg} />}

				<div
					className={
						msg.type === "working" || msg.type === "success" ? "hidden" : ""
					}
				>
					<Submit
						name="Update Participant Details"
						icon={faChevronCircleRight}
					/>
				</div>
			</div>
		</form>
	);
}

export default GuestDetailsForm;
