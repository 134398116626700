import Input from "../Input";
import Select from "../Select";

function AddressFields({ address, city, st, zip, errors }) {
	return (
		<>
			<div className="form-row-1">
				<Input
					type="text"
					label="Address"
					req={true}
					name="address"
					value={address}
					errors={errors}
					autocomplete="off"
				/>
			</div>

			<div className="form-row-3 address" style={{ marginBottom: "25px" }}>
				<Input
					type="text"
					label="City"
					req={true}
					name="city"
					value={city}
					errors={errors}
					autocomplete="off"
				/>

				<Select
					name="st"
					label="State / Province"
					req={true}
					value={st}
					options={[
						{ value: "", name: "--" },
						{
							name: "Alabama",
							value: "AL",
						},
						{
							name: "Alaska",
							value: "AK",
						},
						{
							name: "Arizona",
							value: "AZ",
						},
						{
							name: "Arkansas",
							value: "AR",
						},
						{
							name: "California",
							value: "CA",
						},
						{
							name: "Colorado",
							value: "CO",
						},
						{
							name: "Connecticut",
							value: "CT",
						},
						{
							name: "Delaware",
							value: "DE",
						},
						{
							name: "District of Columbia",
							value: "DC",
						},
						{
							name: "Florida",
							value: "FL",
						},
						{
							name: "Georgia",
							value: "GA",
						},
						{
							name: "Hawaii",
							value: "HI",
						},
						{
							name: "Idaho",
							value: "ID",
						},
						{
							name: "Illinois",
							value: "IL",
						},
						{
							name: "Indiana",
							value: "IN",
						},
						{
							name: "Iowa",
							value: "IA",
						},
						{
							name: "Kansas",
							value: "KS",
						},
						{
							name: "Kentucky",
							value: "KY",
						},
						{
							name: "Louisiana",
							value: "LA",
						},
						{
							name: "Maine",
							value: "ME",
						},
						{
							name: "Maryland",
							value: "MD",
						},
						{
							name: "Massachusetts",
							value: "MA",
						},
						{
							name: "Michigan",
							value: "MI",
						},
						{
							name: "Minnesota",
							value: "MN",
						},
						{
							name: "Mississippi",
							value: "MS",
						},
						{
							name: "Missouri",
							value: "MO",
						},
						{
							name: "Montana",
							value: "MT",
						},
						{
							name: "Nebraska",
							value: "NE",
						},
						{
							name: "Nevada",
							value: "NV",
						},
						{
							name: "New Hampshire",
							value: "NH",
						},
						{
							name: "New Jersey",
							value: "NJ",
						},
						{
							name: "New Mexico",
							value: "NM",
						},
						{
							name: "New York",
							value: "NY",
						},
						{
							name: "North Carolina",
							value: "NC",
						},
						{
							name: "North Dakota",
							value: "ND",
						},
						{
							name: "Ohio",
							value: "OH",
						},
						{
							name: "Oklahoma",
							value: "OK",
						},
						{
							name: "Oregon",
							value: "OR",
						},
						{
							name: "Pennsylvania",
							value: "PA",
						},
						{
							name: "Puerto Rico",
							value: "PR",
						},
						{
							name: "Rhode Island",
							value: "RI",
						},
						{
							name: "South Carolina",
							value: "SC",
						},
						{
							name: "South Dakota",
							value: "SD",
						},
						{
							name: "Tennessee",
							value: "TN",
						},
						{
							name: "Texas",
							value: "TX",
						},
						{
							name: "Utah",
							value: "UT",
						},
						{
							name: "Vermont",
							value: "VT",
						},
						{
							name: "Virgin Islands",
							value: "VI",
						},
						{
							name: "Virginia",
							value: "VA",
						},
						{
							name: "Washington",
							value: "WA",
						},
						{
							name: "West Virginia",
							value: "WV",
						},
						{
							name: "Wisconsin",
							value: "WI",
						},
						{
							name: "Wyoming",
							value: "WY",
						},
						{
							name: "Alberta",
							value: "AB",
						},
						{
							name: "British Columbia",
							value: "BC",
						},
						{
							name: "Manitoba",
							value: "MB",
						},
						{
							name: "New Brunswick",
							value: "NB",
						},
						{
							name: "Newfoundland and Labrador",
							value: "NL",
						},
						{
							name: "Northwest Territories",
							value: "NT",
						},
						{
							name: "Nova Scotia",
							value: "NS",
						},
						{
							name: "Nunavut",
							value: "NU",
						},
						{
							name: "Ontario",
							value: "ON",
						},
						{
							name: "Prince Edward Island",
							value: "PE",
						},
						{
							name: "Quebec",
							value: "QC",
						},
						{
							name: "Saskatchewan",
							value: "SK",
						},
						{
							name: "Yukon",
							value: "YT",
						},
					]}
					errors={errors}
					autocomplete="off"
				/>

				<Input
					type="text"
					label="Zip / Postal Code"
					req={true}
					name="zip"
					value={zip}
					errors={errors}
					autocomplete="off"
				/>
			</div>
		</>
	);
}

export default AddressFields;
