import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { API_URL_PROAM as API_URL, getValFromLS } from "../../utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronCircleRight,
	faCircleCheck,
	faCircleXmark,
	faCircleExclamation,
	faClock,
	faHourglass,
	faPencilSquare,
} from "@fortawesome/pro-light-svg-icons";

import Select from "../../shared/Select";
import Msg from "../../shared/Msg";
import Submit from "../../shared/Submit";

function Form({ data, handleSuccess }) {
	const [msg, setMsg] = useState({});

	const formElement = useRef();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=dashboardStatuses`);
		let formData = new FormData(formElement.current);
		formData.append("event", data.id);
		formData.append("auth", getValFromLS("proam-dash", true));
		formData.append("x", uuidv4());

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({
					type: "success",
					text: json.text,
				});
				handleSuccess(json.text);
			} else {
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<form
			ref={formElement}
			method="post"
			action="/"
			onSubmit={(e) => handleSubmit(e)}
			style={{ marginTop: "25px" }}
		>
			<table>
				<thead>
					<tr>
						<th colSpan="5" className="hdg">
							{`${data.name} Participant Status`}
						</th>
					</tr>
					<tr className={data.groups[0] === "0" ? "hidden" : ""}>
						<td className="left">Guest Name</td>
						<td className="center" style={{ width: "15%" }}>
							Group #
						</td>
						<td className="center" style={{ width: "15%" }}>
							Status
						</td>
						<td className="center" style={{ width: "15%" }}>
							Details
						</td>
						<td className="center" style={{ width: "10%" }}>
							Edit
						</td>
					</tr>
				</thead>
				{data.guests.length > 0 ? (
					<tbody>
						{data.guests.map((item) => {
							return (
								<tr key={item.guestId}>
									<td className="left">
										<span>
											<Link to={`/dashboard/guest/${item.id}`}>
												{item.name}
											</Link>
										</span>
									</td>
									<td className="center group-select">
										<Select
											name={`guestId[${item.guestId}]`}
											label=""
											req={false}
											value={item.groupNumber}
											options={data.groups.map((group) => ({
												value: group,
												name: group,
											}))}
											errors={[]}
											disabled={data.groups.length === 1 ? true : false}
										/>
									</td>
									<Td status={item.status} text={item.text} />
									<Td2 attention={item.attention} />
									<Td3 id={item.id} status={item.status} />
								</tr>
							);
						})}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td
								className="center"
								colSpan="5"
								style={{ paddingTop: "15px", paddingBottom: "15px" }}
							>
								<span className="working">
									{data.groups[0] === "0"
										? " -- Not participating in this Pro-Am --"
										: " -- No guests statuses found --"}
								</span>
							</td>
						</tr>
					</tbody>
				)}
				<tfoot
					style={{
						display: data.groups.length === 1 ? "none" : "table-footer-group",
					}}
				>
					<tr>
						<td colSpan="5" className="group-select">
							<div className="msg-submit">
								{msg.type && <Msg data={msg} noTopMargin={true} />}

								<div className={msg.type === "working" ? "hidden" : ""}>
									<Submit
										name="Update Group Assignments"
										icon={faChevronCircleRight}
									/>
								</div>
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		</form>
	);
}

export default Form;

function Td({ status, text }) {
	return (
		<td
			className={
				status === 1
					? "left one"
					: status === 0
					? "left zero"
					: status === 2
					? "left two"
					: status === 4 || status === 5
					? "left four"
					: "left two"
			}
		>
			<div className="flex">
				{status === 1 ? (
					<FontAwesomeIcon icon={faCircleCheck} />
				) : status === 0 ? (
					<FontAwesomeIcon icon={faCircleXmark} />
				) : status === 2 ? (
					<FontAwesomeIcon icon={faHourglass} />
				) : status === 4 ? (
					<FontAwesomeIcon icon={faClock} />
				) : (
					<FontAwesomeIcon icon={faCircleExclamation} />
				)}

				<span>{text}</span>
			</div>
		</td>
	);
}

function Td2({ attention }) {
	return (
		<td
			className={attention ? "center three" : "center one"}
			style={{
				fontSize: "115%",
				paddingLeft: "0",
				paddingTop: "5px",
			}}
		>
			<FontAwesomeIcon icon={attention ? faCircleExclamation : faCircleCheck} />
		</td>
	);
}

function Td3({ lang, id, status }) {
	return (
		<td className="center">
			{status === 1 ? (
				<Link
					to={`/dashboard/guest/${id}/edit`}
					title={
						lang === "es" ? "Complete Guest Details" : "Complete Guest Details"
					}
				>
					<FontAwesomeIcon icon={faPencilSquare} size="lg" />
				</Link>
			) : (
				<span />
			)}
		</td>
	);
}
